var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"evaluation-details"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticClass:"tabs"},[(_vm.$authFunProxy['evaluation.index-my'])?_c('router-link',{class:{
        active: _vm.$route.name === 'evaluation.details.draw'
      },attrs:{"tag":"span","to":{
        name: 'evaluation.details.draw',
        query: { id: _vm.$route.query.id }
      }}},[_vm._v("个人总结填写")]):_vm._e(),(_vm.$authFunProxy['evaluation.index-mycheck'])?_c('router-link',{class:{
        active: _vm.$route.name === 'evaluation.details.opinion'
      },attrs:{"tag":"span","to":{
        name: 'evaluation.details.opinion',
        query: { id: _vm.$route.query.id }
      }}},[_vm._v("个人意见填写")]):_vm._e(),(_vm.$authFunProxy['evaluation.index-orgcheck'] || _vm.$authFunProxy['evaluation.index-leadcheck'])?_c('router-link',{class:{
        active: _vm.$route.name === 'evaluation.details.approve'
      },attrs:{"tag":"span","to":{
        name: 'evaluation.details.approve',
        query: { id: _vm.$route.query.id }
      }}},[_vm._v("考核意见填写"),_c('Badge',{attrs:{"count":_vm.total}})],1):_vm._e()],1),_c('i',{staticClass:"fmico fmico-cha",staticStyle:{"cursor":"pointer","margin-right":"10px"},on:{"click":function($event){return _vm.$router.replace({name: 'evaluation.index'})}}})]),_c('router-view',{staticClass:"scrollbar content"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }